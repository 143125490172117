import { types } from 'const/types.task'
import { session } from 'store/state'

export function PendingRequiredFields(tabs) {
    const arr = tabs.reduce((arr, tab) => {
        const fields = tab.childs.reduce((arr, element) => {
            if (element.type === types.section) {
                const aux = element.childs.map((child) => {
                    return {
                        name: child.code,
                        tab: child.tab,
                        required: child.must && child.value === undefined,
                    }
                })
                arr = arr.concat(aux)
            }
            arr.push({
                name: element.code,
                tab: element.tab,
                required: element.must && element.value === undefined,
            })
            return arr
        }, [])
        arr = arr.concat(fields)
        return arr
    }, [])
    return arr.filter((f) => f.required)
}

export function PendingFilteredFields(fields, inputs) {
    const values = inputs.reduce((arr, item) => {
        if (item.value !== undefined) {
            arr.push(item.name)
        }
        return arr
    }, [])
    return fields !== undefined ? fields.filter((f) => values.map((i) => i).indexOf(f.name) < 0) : []
}

export function FindConditioned(rules) {
    let result = []
    const execution = rules.filter((r) => r.tipo === 1)
    if (execution && execution.length > 0) {
        result = execution.reduce((arr, r) => {
            arr = arr.concat(
                r.accion.filter((f) => f.parametros && f.parametros.campo).map((a) => a.parametros.campo.codigo),
            )
            return arr
        }, [])
    }
    return result
}

export function FindConditions(rules) {
    let result = []
    const execution = rules.filter((r) => r.tipo === 1)
    if (execution && execution.length > 0) {
        result = execution.reduce((arr, r) => {
            arr = arr.concat(
                r.condicion.filter((f) => f.parametros && f.parametros.campo).map((a) => a.parametros.campo.codigo),
            )
            return arr
        }, [])
    }
    return result
}

export function HideOld(toHide, tabs, value, user) {
    return tabs.map((item) => {
        if (toHide.indexOf(item.codigo) >= 0) {
            if (item.oculto !== value) {
                //const d = new Date()
                //item.fechahoramodificacion = formatDate(d) + ' ' + formatTime(d)
                //item.usuariomodificacion = sessionStorage.user
            }
            item.oculto = value
            item.new =
                !value &&
                !(
                    item.valor !== undefined &&
                    item.valor !== '' &&
                    item.valor !== null &&
                    item.valor.toString().length > 0 &&
                    item.valor.toString() !== '[]'
                )
        }
        if (item.hijos) {
            item.hijos = HideOld(toHide, item.hijos, value, user)
        }
        return item
    })
}

export function CleanHideValue(tabs, user) {
    return tabs.map((item) => {
        if (item.oculto) {
            if (
                item.valor !== undefined &&
                item.valor !== '' &&
                item.valor !== null &&
                item.valor.toString().length > 0 &&
                item.valor.toString() !== '[]'
            ) {
                const d = new Date()
                item.fechahoramodificacion = formatDate(d) + ' ' + formatTime(d)
                item.usuariomodificacion = user || session().user
            }

            item.valor = undefined
            item.comentario = undefined
            if (typeof item.metadatos !== 'object' || item.metadatos === null) {
                item.metadatos = {}
            }
            if (typeof item.metadatos === 'object') {
                item.metadatos.adjuntos = []
            }
            if (item.hijos) {
                item.hijos = MustCleanHideValue(item.hijos, user)
                item.pendiente = calculoPendiente(item.hijos)
                calculoPuntuacion(item, item.hijos)
            } else {
                item.puntuacionefectiva = 0
                item.puntuacionposible = item.puntuacion
            }
        }
        if (item.hijos) {
            item.hijos = CleanHideValue(item.hijos, user)
            item.pendiente = calculoPendiente(item.hijos)
            calculoPuntuacion(item, item.hijos)
        }
        return item
    })
}

export function MustCleanHideValue(tabs, user) {
    return tabs.map((item) => {
        if (item.hijos) {
            item.hijos = MustCleanHideValue(item.hijos, user)
        }
        if (
            item.valor !== undefined &&
            item.valor !== '' &&
            item.valor !== null &&
            item.valor.toString().length > 0 &&
            item.valor.toString() !== '[]'
        ) {
            const d = new Date()
            item.fechahoramodificacion = formatDate(d) + ' ' + formatTime(d)
            item.usuariomodificacion = user || session().user

            if (item.hijos) {
                item.pendiente = calculoPendiente(item.hijos)
                calculoPuntuacion(item, item.hijos)
            } else {
                item.puntuacionefectiva = 0
                item.puntuacionposible = item.puntuacion
            }
        }
        item.valor = undefined
        item.comentario = undefined
        if (typeof item.metadatos === 'object') item.metadatos.adjuntos = []
        return item
    })
}

export function RunRules(rules, tabs, user) {
    let items = [...tabs]
    let valid = rules.filter((r) => r.tipo === 1)
    let i = 0
    let _continue = true
    let _executed = false
    while (valid.length > 0 && _continue) {
        const current = valid[i]
        let rest = JSON.parse(JSON.stringify(valid))
        delete rest[i]
        rest = rest.filter((f) => f)
        const ran = RunRule(current, items, rest, user)
        if (ran) {
            _executed = true
            delete valid[i]
            valid = valid.filter((f) => f)
        }
        if (i + 1 >= valid.length) {
            if (_executed) {
                i = 0
                _executed = false
            } else {
                _continue = false
            }
        } else {
            i++
        }
    }
    return CleanHideValue(items, user)
}

export function RunRule(rule, elements, rules, user) {
    const conditions = FindConditions([rule])
    const results = FindConditioned(rules)
    let _found = false
    for (let i = 0; i <= conditions.length; i++ && !_found) {
        _found = results.indexOf(conditions[i]) >= 0
    }
    if (_found) {
        return false
    }

    const isOk = rule.condicion.reduce(
        (result, c) => {
            const check = ValidateCondition(c, elements, rule)
            if (rule.yo === 1) result = result && check
            else result = result || check
            return result
        },
        rule.yo === 1 ? true : false,
    )
    if (isOk) {
        const arr = rule.accion.map((a) => a.parametros.campo.codigo)
        elements = HideOld(arr, elements, false, user)
    }
    return isOk
}

export function estaSuspenso(arr, types) {
    const result = arr.reduce((result, i) => {
        if (i.hijos && i.hijos.length > 0) {
            result = result || estaSuspenso(i.hijos, types)
        } else {
            result = result || (i.alertarango && estaCritico(i) && types.indexOf(i.criticoSuspende) >= 0)
        }
        return result
    }, false)
    return result
}

export function calculoPendienteRecursivo(arr) {
    return arr
        .filter((f) => !f.oculto && !f.desactivado)
        .reduce((result, i) => {
            if (i.hijos && i.hijos.length > 0) {
                i.pendiente = calculoPendienteRecursivo(i.hijos)
            }
            result = result || calculoPendienteCampo(i)
            return result
        }, false)
}

export function calculoPendiente(arr) {
    return arr
        .filter((f) => !f.oculto && !f.desactivado)
        .reduce((result, i) => {
            result = result || calculoPendienteCampo(i)
            return result
        }, false)
}

export function calculoAlerta(arr) {
    return arr
        .filter((f) => !f.oculto && !f.desactivado)
        .reduce((result, i) => {
            result = result || i.alertarango
            return result
        }, false)
}

export function estaCritico(element) {
    let result = false
    if (element.critico) {
        switch (element.type) {
            case types.multioption:
                result =
                    element.valor && element.critico && element.critico.valores
                        ? element.critico.valores.reduce((result, v) => {
                              return (
                                  result ||
                                  element.valor
                                      .split(',')
                                      .map((m) => m.toString())
                                      .indexOf(v.toString()) >= 0
                              )
                          }, false)
                        : false
                break
            case types.options:
                result =
                    element.valor && element.critico && element.critico.valores
                        ? element.critico.valores.map((m) => m.toString()).indexOf(element.valor.toString()) >= 0
                        : false
                break
            default:
                result = true
        }
    }
    return result
}

export function attachedFilled(data) {
    const filled =
        (data.tipo === 'IMAGEN' &&
            data.metadatos &&
            Array.isArray(data.metadatos.adjuntos) &&
            data.metadatos.adjuntos.some((a) => a.tipo === 'CAMARA' || a.tipo === 'IMAGEN')) ||
        (data.tipo === 'ARCHIVO' &&
            data.metadatos &&
            Array.isArray(data.metadatos.adjuntos) &&
            data.metadatos.adjuntos.some((a) => a.tipo === 'ARCHIVO')) ||
        (data.valor !== undefined &&
            data.valor !== '' &&
            data.valor !== null &&
            data.valor.toString().length > 0 &&
            data.valor.toString() !== '[]') ||
        data.tipo === 'PESTAÑA'

    const result =
        !data.flagsAdjuntos ||
        !Array.isArray(data.flagsAdjuntos) ||
        data.flagsAdjuntos.filter((f) => f.indexOf('MD_RA_') >= 0 || f.indexOf('MD_R_') >= 0).length === 0 ||
        (Array.isArray(data.flagsAdjuntos) &&
            filled &&
            data.flagsAdjuntos.some((f) => f.indexOf('MD_R_') >= 0) &&
            data.metadatos &&
            Array.isArray(data.metadatos.adjuntos) &&
            data.flagsAdjuntos
                .filter((f) => f.indexOf('MD_R_') >= 0)
                .some((r) => {
                    return (
                        data.metadatos.adjuntos.filter((s) => s.tipo === r.replace('MD_R_', '')).length > 0 &&
                        data.metadatos.adjuntos
                            .filter((s) => s.tipo === r.replace('MD_R_', ''))
                            .some((t) => (t.tipo === 'TEXTO' && !!t.comentario) || (t.tipo !== 'TEXTO' && !!t.base))
                    )
                })) ||
        (Array.isArray(data.flagsAdjuntos) &&
            data.alertarango &&
            filled &&
            data.flagsAdjuntos.some((f) => f.indexOf('MD_RA_') >= 0) &&
            data.metadatos &&
            Array.isArray(data.metadatos.adjuntos) &&
            data.flagsAdjuntos
                .filter((f) => f.indexOf('MD_RA_') >= 0)
                .some((r) => {
                    return (
                        data.metadatos.adjuntos.filter((s) => s.tipo === r.replace('MD_RA_', '')).length > 0 &&
                        data.metadatos.adjuntos
                            .filter((s) => s.tipo === r.replace('MD_RA_', ''))
                            .some((t) => (t.tipo === 'TEXTO' && !!t.comentario) || (t.tipo !== 'TEXTO' && !!t.base))
                    )
                })) ||
        (filled && !data.alertarango && data.flagsAdjuntos.some((f) => f.indexOf('MD_RA_') >= 0)) ||
        (!filled &&
            !(
                data.flagsAdjuntos.some((f) => f.indexOf('MD_R_') >= 0) ||
                (data.alertarango && data.flagsAdjuntos.some((f) => f.indexOf('MD_RA_') >= 0))
            ))
    return result && (filled || !data.obligatorio)
}

export function calculoPendienteCampo(i) {
    let result = false
    if (i.hijos && i.hijos.length > 0) {
        result = result || (i.pendiente && !i.desactivado && !i.oculto)
    } else if (i.tipo === 'ARCHIVO' && i.obligatorio) {
        result = result || !attachedFilled(i)
    } else if (i.tipo === 'IMAGEN' && i.obligatorio) {
        result =
            result ||
            !(
                i.metadatos &&
                Array.isArray(i.metadatos.adjuntos) &&
                i.metadatos.adjuntos.some((a) => a.tipo === 'CAMARA' || a.tipo === 'IMAGEN')
            )
    } else if (
        i.valor !== undefined &&
        i.valor !== '' &&
        i.valor !== null &&
        i.valor.toString().length > 0 &&
        i.valor.toString() !== '[]' &&
        attachedFilled(i)
    ) {
        result = result || false
    } else if (i.tipo === 'PESTANA' || i.tipo === 'SECCION') {
        result = result || false
    } else {
        result = result || (i.obligatorio && !i.desactivado && !i.oculto && !i.censurado)
    }
    return result
}

export function calculoPuntuacion(item, arr) {
    if (arr !== undefined && arr.length > 0) {
        arr.map((hijo) => calculoPuntuacion(hijo, hijo.hijos))
        const puntuacionhijos = arr.reduce((total, hijo) => {
            if (!hijo.desactivado && !hijo.oculto) {
                total += hijo.puntuacionposible
            }
            return total
        }, 0)
        const puntoshijos = arr.reduce((total, hijo) => {
            if (!hijo.desactivado && !hijo.oculto) {
                total += hijo.puntuacionefectiva
            }
            return total
        }, 0)

        if (item.puntuacion === 0) {
            item.puntuacionposible = puntuacionhijos
            item.puntuacionefectiva = puntoshijos
        } else {
            item.puntuacionposible = item.puntuacion
            if (puntuacionhijos > 0) {
                item.puntuacionefectiva = Math.round((puntoshijos / puntuacionhijos) * item.puntuacionposible * 10) / 10
            } else {
                item.puntuacionefectiva = 0
            }
        }
        if (item.supendido) {
            item.puntuacionefectiva = Math.min(0, item.puntuacionefectiva)
        }
    }
}

export function ValidateCondition(condition, tabs, rule) {
    const codigo = condition.parametros.campo.codigo
    const valor = condition.parametros.valor
    const field = findItem(tabs, codigo)

    if (!field || (field.oculto && condition.tipo !== 11)) {
        return false
    }
    switch (condition.tipo) {
        case 0:
            return (
                field.valor !== undefined &&
                field.valor !== '' &&
                field.valor !== null &&
                field.valor.toString().length > 0 &&
                field.valor.toString() !== '[]'
            )
        case 1:
            return !(
                field.valor !== undefined &&
                field.valor !== '' &&
                field.valor !== null &&
                field.valor.toString().length > 0 &&
                field.valor.toString() !== '[]'
            )
        case 2:
            return field.valor === true
        case 3:
            return field.valor === false
        case 4:
            return !!field.comentario
        case 5: // on range
            switch (field.type) {
                case types.boolean:
                case types.action:
                    return field.alertable !== undefined && field.valor !== field.alertable
                case types.number:
                case types.real:
                case types.auto:
                case types.temp:
                    return field.valor && !isOutRangeReal(field.valor, field.alertable)
                case types.date:
                    return field.valor && !isOutRangeDate(field.valor, field.alertable)
                case types.datetime:
                    return field.valor && !isOutRangeDateTime(field.valor, field.alertable)
                case types.entity:
                    return field.valor && !isOutRangeReal(field.valor, field.alertable)
                default:
                    return false
            }
        case 6: // out range
            switch (field.type) {
                case types.boolean:
                case types.action:
                    return field.alertable !== undefined && field.valor === field.alertable
                case types.number:
                case types.real:
                case types.auto:
                case types.temp:
                    return isOutRangeReal(field.valor, field.alertable)
                case types.date:
                    return isOutRangeDate(field.valor, field.alertable)
                case types.datetime:
                    return isOutRangeDateTime(field.valor, field.alertable)
                case types.entity:
                    return isOutRangeReal(field.valor, field.alertable)
                default:
                    return false
            }
        case 7: // multiseleted option
            const options = field.valor ? field.valor.split(',').length : 0
            return options > 1
        case 8: // temp modified
            return field && field.metados && field.metados.manually
        case 9: // option selected
            switch (field.type) {
                case types.multioption:
                    const values = field.valor ? field.valor.toString().split(',') : []
                    return valor && values.indexOf(valor.toString()) >= 0
                case types.options:
                    return valor && field.valor && field.valor.toString() === valor.toString()
                default:
                    return false
            }
        case 10:
            return !field.oculto
        case 11:
            return field.oculto
        case 12:
            return valor && field.comentario && field.comentario.toString() === valor.toString()
        default:
            return false
    }
}

export function findItem(arr, codigo) {
    let item = undefined
    arr.forEach((i) => {
        if (i.hijos && i.hijos.length > 0) {
            let aux = findItem(i.hijos, codigo)
            if (aux) {
                item = aux
            }
        }
        if (i.codigo === codigo) {
            item = i
        }
    })
    return item
}

export const isOutRangeReal = (valor, range) => {
    let result = false
    if (range && (valor !== null && valor !== undefined && valor !== '')) {
        const reference = parseFloat(valor)
        if (range.inferior && range.limiteinferior) {
            result = result || reference < parseFloat(range.inferior)
        } else if (range.inferior) {
            result = result || reference <= parseFloat(range.inferior)
        }
        if (range.superior && range.limitesuperior) {
            result = result || reference > parseFloat(range.superior)
        } else if (range.superior) {
            result = result || reference >= parseFloat(range.superior)
        }
    }
    return result
}

export function parseDate(input) {
    // eslint-disable-next-line
    const format = input.match(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/)
        ? 'dd/mm/yyyy'
        : 'yyyy-mm-dd' // default format
    var parts = input.match(/(\d+)/g),
        i = 0,
        fmt = {}
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function(part) {
        fmt[part] = i++
    })

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']])
}

function parseDateTime(input) {
    const date = input.replace('T', ' ').split(' ')[0]
    const time = input.replace('T', ' ').split(' ')[1]
    // eslint-disable-next-line
    const format = date.match(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/)
        ? 'dd/mm/yyyy'
        : 'yyyy-mm-dd' // default format
    var parts = date.match(/(\d+)/g),
        i = 0,
        fmt = {}
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function(part) {
        fmt[part] = i++
    })

    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]).setHours(
        time.split(':')[0],
        time.split(':')[1],
        0,
        0,
    )
}

export const isOutRangeDate = (date, range) => {
    const conditions = range && (range.inferior || range.superior) ? {} : undefined
    if (range.inferior) {
        var fechaInferior = new Date()
        fechaInferior.setDate(fechaInferior.getDate() + parseInt(range.inferior, 10) + (range.limiteinferior ? 0 : 1))
        conditions.min = formatDate(fechaInferior)
    }
    if (range.superior) {
        var fechaSuperior = new Date()
        fechaSuperior.setDate(fechaSuperior.getDate() + parseInt(range.superior, 10) - (range.limitesuperior ? 0 : 1))
        conditions.max = formatDate(fechaSuperior)
    }
    if (!conditions) return false
    else {
        if (!date) return false
        else {
            if (conditions.min && new Date(parseDate(date)).valueOf() < new Date(parseDate(conditions.min)).valueOf()) {
                return true
            }
            if (conditions.max && new Date(parseDate(date)).valueOf() > new Date(parseDate(conditions.max)).valueOf()) {
                return true
            }
            return false
        }
    }
}

export const isOutRangeDateTime = (date, range) => {
    const conditions = range && (range.inferior || range.superior) ? {} : undefined
    if (range.inferior) {
        conditions.min = formatDateTime(
            new Date(
                new Date().getTime() - (parseInt(range.inferior, 10) + (range.limiteinferior ? 0 : 1)) * 60 * 60 * 1000,
            ),
        )
    }
    if (range.superior) {
        conditions.max = formatDateTime(
            new Date(
                new Date().getTime() + (parseInt(range.superior, 10) - (range.limitesuperior ? 0 : 1)) * 60 * 60 * 1000,
            ),
        )
    }
    if (!conditions) return false
    else {
        if (!date) return false
        else {
            if (
                conditions.min &&
                new Date(parseDateTime(date)).valueOf() < new Date(parseDateTime(conditions.min)).valueOf()
            ) {
                return true
            }
            if (
                conditions.max &&
                new Date(parseDateTime(date)).valueOf() > new Date(parseDateTime(conditions.max)).valueOf()
            ) {
                return true
            }
            return false
        }
    }
}

export function formatDateTime(d) {
    if (d instanceof Date) {
        function pad(s) {
            return s < 10 ? '0' + s : s
        }
        return (
            [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-') +
            ' ' +
            [pad(d.getHours()), pad(d.getMinutes())].join(':')
        )
    }
    return d
}

export function formatDate(d) {
    if (d) {
        function pad(s) {
            return s < 10 ? '0' + s : s
        }
        return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-')
    }
    return d
}

export function formatTime(d) {
    function pad(s) {
        return s < 10 ? '0' + s : s
    }
    return [pad(d.getHours()), pad(d.getMinutes()), pad(d.getSeconds())].join(':')
}

export function isCondition(rule, code) {
    return rule.condicion.reduce((is, a) => {
        if (a.parametros && a.parametros.campo && a.parametros.campo.codigo === code) {
            is = true
        }
        return is
    }, false)
}

export function isAction(rule, code) {
    return rule.accion.reduce((is, a) => {
        if (a.parametros && a.parametros.campo && a.parametros.campo.codigo === code) {
            is = true
        }
        return is
    }, false)
}
